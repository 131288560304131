import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// Models
import { IDisk } from 'models/Disk'
// Styles
import * as styles from './SongDisks.module.css'

type SongDisksProps = {
    disks: IDisk[]
}

const SongDisks: React.FC<SongDisksProps> = ({ disks }) => {
    return (
        <section className={styles.section}>
            <h2 className={styles.title}>Aparece en</h2>
            <div className={styles.song_disks}>
                {disks.map((disk) => {
                    const { name, slug, cover } = disk
                    return (
                        <Link
                            key={slug}
                            className={styles.song_disk}
                            to={`/discography/${slug}`}
                        >
                            <GatsbyImage
                                className={styles.song_disk_cover}
                                image={cover.gatsbyImageData}
                                alt={name}
                            />
                            <h3 className={styles.song_disk_name}>{name}</h3>
                        </Link>
                    )
                })}
            </div>
        </section>
    )
}

export default SongDisks
