import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SongHeader from 'components/Sections/Song/SongHeader'
import SongContent from 'components/Sections/Song/SongContent'
// Models
import { IOptionalMetaProps } from 'models/SEO'
import { ISong } from 'models/Song'
import { IDisk } from 'models/Disk'

type SongPageProps = {
    song: ISong
    disks: {
        nodes: IDisk[]
    }
}

const SongPage: React.FC<PageProps<SongPageProps>> = ({ data }) => {
    const song = data.song
    const disks = data.disks.nodes
    const { name, thumbnail } = song
    const seo: IOptionalMetaProps = {
        title: name,
        description: `Letra de la canción ${name}.`,
        image: thumbnail.file?.url,
    }

    return (
        <Layout seo={seo}>
            <SongHeader title={song.name} image={thumbnail} song={song} />
            <SongContent song={song} disks={disks} />
        </Layout>
    )
}

export default SongPage

export const pageQuery = graphql`
    query SongPage($slug: String!) {
        song: contentfulSongs(slug: { eq: $slug }) {
            name
            name_es
            name_jap
            slug

            release_date
            duration

            youtubeMusic
            youtube
            spotify
            amazonMusic
            deezer

            thumbnail {
                gatsbyImageData(layout:CONSTRAINED)
                file {
                    url
                }
            }

            about {
                raw
            }
            lyric {
                raw
            }
            lyric_rom {
                raw
            }
            lyric_es {
                raw
            }
            sidenotes {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(layout:CONSTRAINED)
                    }
                }
            }

            translators {
                name
                username
            }

            authors {
                name
                slug
            }

            cc_es {
                file {
                    url
                    fileName
                    contentType
                }
            }

            cc_rom {
                file {
                    url
                    fileName
                    contentType
                }
            }

            cc_jp {
                file {
                    url
                    fileName
                    contentType
                }
            }
        }

        disks: allContentfulDisks(
            filter: { songs: { elemMatch: { slug: { eq: $slug } } } }
            sort: { fields: release_date, order: DESC }
        ) {
            nodes {
                name
                slug
                type
                release_date
                cover {
                    gatsbyImageData
                    file {
                        url
                    }
                }
            }
        }
    }
`
