import * as React from 'react'

function FlagJapan(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            {...props}
        >
            <path
                d="M473.655 88.275H38.345C17.167 88.275 0 105.442 0 126.62v258.76c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345V126.62c0-21.178-17.167-38.345-38.345-38.345z"
                fill="#f5f5f5"
            />
            <circle cx={256} cy={255.999} r={97.1} fill="#ff4b55" />
        </svg>
    )
}

export default FlagJapan
