import React from 'react'
// Models
import SongNotes from 'components/Sections/Song/SongNotes'
import SongLyrics from 'components/Sections/Song/SongLyrics'
import SongMedia from 'components/Sections/Song/SongMedia'
import SongDisks from 'components/Sections/Song/SongDisks'
import Share from 'components/UIElements/Share'
// Models
import { ISong } from 'models/Song'
import { IDisk } from 'models/Disk'

type SongContentProps = {
    song: ISong
    disks: IDisk[]
}

const SongContent: React.FC<SongContentProps> = ({ song, disks }) => {
    const { name, sidenotes, about } = song
    return (
        <>
            <SongMedia song={song} />
            {about && <SongNotes title="Sobre la canción" notes={about} />}
            <SongLyrics song={song} />
            {sidenotes && (
                <SongNotes title="Notas de la traducción" notes={sidenotes} />
            )}
            {disks.length > 0 && <SongDisks disks={disks} />}
            <Share message={name} />
        </>
    )
}

export default SongContent
