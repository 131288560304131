import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getFormattedDate } from 'util/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSongDuration } from 'util/time'
// SVG
import JapanFlag from 'svg/flags/japan'
import SpainFlag from 'svg/flags/spain'
// Models
import { IImage } from 'models/Image'
import { ISong } from 'models/Song'
// Styles
import * as styles from './SongHeader.module.css'

type SongHeaderProps = {
    image: IImage
    title: string
    song: ISong
}

const SongHeader: React.FC<SongHeaderProps> = ({ image, title, song }) => {
    const { name, name_es, name_jap, release_date } = song
    const date = getFormattedDate(release_date, { short: true })
    const time = getSongDuration(song)
    return (
        <header className={styles.song_header}>
            <GatsbyImage
                className={styles.song_background}
                image={image.gatsbyImageData}
                alt={title}
            />
            <div className={styles.song_data}>
                <div className={styles.song_name}>
                    <h2 className={styles.song_name_rom}>{name}</h2>
                    {(name_jap || name_es) && (
                        <span className={styles.song_alt_names}>
                            {name_jap && (
                                <>
                                    <h3 className={styles.song_name_jp}>
                                        <JapanFlag width={16} />
                                        {name_jap}
                                    </h3>
                                </>
                            )}
                            {name_es && (
                                <>
                                    <h3 className={styles.song_name_es}>
                                        <SpainFlag width={16} />
                                        {name_es}
                                    </h3>
                                </>
                            )}
                        </span>
                    )}
                </div>
                <div className={styles.song_date_and_time}>
                    <span>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={['far', 'clock']}
                        />
                        {time}
                    </span>
                    <span>
                        <FontAwesomeIcon
                            className={styles.icon}
                            icon={['far', 'calendar-alt']}
                        />
                        {date}
                    </span>
                </div>
                <div className={styles.song_authors}>
                    Por:{' '}
                    {song.authors?.map((author, index) => (
                        <React.Fragment key={author.slug}>
                            <Link to={`/artists/${author.slug}`}>
                                {author.name}
                            </Link>
                            {index < song.authors.length - 1 && `, `}
                            {index === song.authors.length - 1 && `.`}
                        </React.Fragment>
                    ))}
                </div>
                {song.translators?.length && (
                    <div className={styles.song_translators}>
                        Traducción:{' '}
                        {song.translators?.map((translator, index) => (
                            <React.Fragment key={translator.username}>
                                <Link to={`/users/${translator.username}`}>
                                    {translator.name}
                                </Link>
                                {index < song.translators!.length - 1 && `, `}
                                {index === song.translators!.length - 1 && `.`}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
        </header>
    )
}

export default SongHeader
