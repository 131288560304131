import React from 'react'
// Components
import RichText from 'components/UIElements/RichText'
// Models
import {
    ContentfulRichTextGatsbyReference,
    RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
// Styles
import * as styles from './SongNotes.module.css'

type SongNotesProps = {
    title: string
    notes: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

const SongNotes: React.FC<SongNotesProps> = ({ title, notes }) => {
    return (
        <section className={styles.section}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.note_content}>
                <RichText alt={title} content={notes} />
            </div>
        </section>
    )
}

export default SongNotes
