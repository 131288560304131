// extracted by mini-css-extract-plugin
export var song_header = "SongHeader-module--song_header--JMDW2";
export var song_background = "SongHeader-module--song_background--VMZMX";
export var song_data = "SongHeader-module--song_data--QbrBL";
export var song_name = "SongHeader-module--song_name--XZFdi";
export var song_name_rom = "SongHeader-module--song_name_rom--qSkGu";
export var song_alt_names = "SongHeader-module--song_alt_names--Gk7cK";
export var song_name_jp = "SongHeader-module--song_name_jp--Vw4Qu";
export var song_name_es = "SongHeader-module--song_name_es---vdyD";
export var song_date_and_time = "SongHeader-module--song_date_and_time--5u2+F";
export var icon = "SongHeader-module--icon--rUiRd";
export var song_authors = "SongHeader-module--song_authors--K2t1D";
export var song_translators = "SongHeader-module--song_translators--33bGt";