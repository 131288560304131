import * as React from 'react'

function SpainFlag(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            {...props}
        >
            <g fill="#c8414b">
                <path d="M0 385.379c0 21.177 17.167 38.345 38.345 38.345h435.31c21.177 0 38.345-17.167 38.345-38.345v-32.276H0v32.276zM473.655 88.276H38.345C17.167 88.276 0 105.443 0 126.621v32.276h512v-32.276c0-21.178-17.167-38.345-38.345-38.345z" />
            </g>
            <path fill="#ffd250" d="M0 158.9h512v194.21H0z" />
            <path
                d="M216.276 256l7.485-33.681c.69-3.102-1.671-6.044-4.849-6.044h-5.272c-3.177 0-5.537 2.943-4.849 6.044L216.276 256z"
                fill="#c8414b"
            />
            <path fill="#f5f5f5" d="M207.45 238.34h17.655v75.03H207.45z" />
            <path fill="#fab446" d="M203.03 229.52h26.483v8.828H203.03z" />
            <g fill="#c8414b">
                <path d="M185.38 256h44.14v8.828h-44.14zM229.517 291.31l-26.483-8.827v-8.828l26.483 8.828zM83.862 256l7.485-33.681c.69-3.102-1.671-6.044-4.849-6.044h-5.272c-3.177 0-5.537 2.943-4.849 6.044L83.862 256z" />
            </g>
            <path
                d="M114.759 229.517a8.829 8.829 0 00-8.828 8.828v57.379c0 10.725 10.01 30.897 44.138 30.897s44.138-20.171 44.138-30.897v-57.379a8.829 8.829 0 00-8.828-8.828h-70.62z"
                fill="#f5f5f5"
            />
            <g fill="#c8414b">
                <path d="M150.069 273.655h-44.138v-35.31a8.829 8.829 0 018.828-8.828h35.31v44.138zM150.069 273.655h44.138v22.069c0 12.189-9.88 22.069-22.069 22.069-12.189 0-22.069-9.88-22.069-22.069v-22.069z" />
            </g>
            <path
                d="M105.931 273.655h44.138v22.069c0 12.189-9.88 22.069-22.069 22.069-12.189 0-22.069-9.88-22.069-22.069v-22.069z"
                fill="#fab446"
            />
            <g fill="#c8414b">
                <path d="M141.241 313.28v-39.625h-8.828v43.693c3.284-.666 6.251-2.12 8.828-4.068zM123.586 317.348v-43.693h-8.828v39.625c2.578 1.948 5.545 3.402 8.828 4.068z" />
            </g>
            <path fill="#ffb441" d="M114.76 256h26.483v8.828H114.76z" />
            <g fill="#fab446">
                <path d="M114.76 238.34h26.483v8.828H114.76z" />
                <path d="M119.17 243.59h17.655v15.992H119.17z" />
            </g>
            <path fill="#f5f5f5" d="M75.03 238.34h17.655v75.03H75.03z" />
            <g fill="#fab446">
                <path d="M70.62 308.97h26.483v8.828H70.62zM70.62 229.52h26.483v8.828H70.62z" />
            </g>
            <path fill="#5064aa" d="M66.21 317.79h35.31v8.828H66.21z" />
            <path fill="#fab446" d="M207.45 308.97h26.483v8.828H207.45z" />
            <path fill="#5064aa" d="M198.62 317.79h35.31v8.828h-35.31z" />
            <path fill="#fab446" d="M123.59 220.69h52.966v8.828H123.59z" />
            <path fill="#ffb441" d="M145.66 194.21h8.828v26.483h-8.828z" />
            <g fill="#f5f5f5">
                <path d="M141.241 207.448c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241.001 7.301-5.939 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414c0 2.435 1.978 4.414 4.414 4.414s4.414-1.978 4.414-4.414a4.417 4.417 0 00-4.414-4.414z" />
                <path d="M158.897 207.448c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241s-5.94 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414 4.417 4.417 0 004.414 4.414 4.417 4.417 0 004.414-4.414 4.418 4.418 0 00-4.414-4.414z" />
                <path d="M176.552 216.276c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241s-5.94 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414 4.417 4.417 0 004.414 4.414c2.435 0 4.414-1.978 4.414-4.414s-1.979-4.414-4.414-4.414zM123.586 216.276c-7.302 0-13.241-5.94-13.241-13.241 0-7.302 5.94-13.241 13.241-13.241 7.302 0 13.241 5.94 13.241 13.241.001 7.301-5.939 13.241-13.241 13.241zm0-17.655a4.417 4.417 0 00-4.414 4.414c0 2.435 1.978 4.414 4.414 4.414S128 205.47 128 203.034a4.416 4.416 0 00-4.414-4.413z" />
            </g>
            <path
                d="M176.552 291.31v4.414c0 2.434-1.98 4.414-4.414 4.414s-4.414-1.98-4.414-4.414v-4.414h8.828m8.827-8.827h-26.483v13.241c0 7.302 5.94 13.241 13.241 13.241 7.302 0 13.241-5.94 13.241-13.241v-13.241h.001z"
                fill="#fab446"
            />
            <path
                d="M172.138 264.828A8.829 8.829 0 01163.31 256v-8.828a8.829 8.829 0 0117.656 0V256a8.829 8.829 0 01-8.828 8.828z"
                fill="#ffa0d2"
            />
            <circle cx={150.07} cy={273.65} r={13.241} fill="#5064aa" />
            <path fill="#fab446" d="M145.66 176.55h8.828v26.483h-8.828z" />
            <path
                d="M123.586 220.69l-8.828-8.828 5.171-5.171a42.627 42.627 0 0160.28 0l5.171 5.171-8.828 8.828h-52.966z"
                fill="#c8414b"
            />
            <g fill="#ffd250">
                <circle cx={150.07} cy={211.86} r={4.414} />
                <circle cx={132.41} cy={211.86} r={4.414} />
                <circle cx={167.72} cy={211.86} r={4.414} />
            </g>
            <g fill="#c8414b">
                <path d="M70.62 256h44.14v8.828H70.62zM70.621 291.31l26.482-8.827v-8.828l-26.482 8.828z" />
            </g>
        </svg>
    )
}

export default SpainFlag
