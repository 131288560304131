import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
// Components
import RichText from 'components/UIElements/RichText'
// Models
import { ISong } from 'models/Song'
// Styles
import * as styles from './SongLyrics.module.css'

type SongLyricsProps = {
    song: ISong
}

enum LyricLang {
    __ES__ = '__ES__',
    __ROM__ = '__ROM__',
    __JP__ = '__JP__',
    __NONE__ = '__NONE__',
}

const defaultLang = (song: ISong) => {
    if (song.lyric_es) return LyricLang.__ES__
    if (song.lyric_rom) return LyricLang.__ROM__
    if (song.lyric) return LyricLang.__JP__
    return LyricLang.__NONE__
}

const SongLyrics: React.FC<SongLyricsProps> = ({ song }) => {
    const { name, lyric, lyric_es, lyric_rom, translators } = song
    const [selectedLang, setSelectedLang] = useState<LyricLang>(() =>
        defaultLang(song)
    )
    const [showSideBySide, setShowSideBySide] = useState<boolean | undefined>(
        () => {
            if (typeof document !== `undefined`)
                return window.innerWidth >= 992 ? true : false
            return undefined
        }
    )
    const [isLyricJPSelected, setIsLyricJPSelected] = useState<boolean>(
        lyric ? true : false
    )
    const [isLyricROMSelected, setIsLyricROMSelected] = useState<boolean>(
        lyric_rom ? true : false
    )
    const [isLyricESSelected, setIsLyricESSelected] = useState<boolean>(
        lyric_es ? true : false
    )

    useEffect(() => {
        const handleResize = () => {
            setShowSideBySide(window.innerWidth >= 992 ? true : false)
        }
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleSideBySide = () => {
        setShowSideBySide((prevState) => !prevState)
    }

    const handleLyricLang = (lang: LyricLang) => {
        if (!showSideBySide) return setSelectedLang(lang)
        if (lang === LyricLang.__ES__) {
            if (!isLyricROMSelected && !isLyricJPSelected) return
            return setIsLyricESSelected((prevState) => !prevState)
        }
        if (lang === LyricLang.__JP__) {
            if (!isLyricESSelected && !isLyricROMSelected) return
            return setIsLyricJPSelected((prevState) => !prevState)
        }
        if (lang === LyricLang.__ROM__) {
            if (!isLyricESSelected && !isLyricJPSelected) return
            return setIsLyricROMSelected((prevState) => !prevState)
        }
    }
    const noLyric = !lyric && !lyric_rom && !lyric_es
    const showLyricJP = showSideBySide
        ? isLyricJPSelected
        : selectedLang === LyricLang.__JP__
    const showLyricROM = showSideBySide
        ? isLyricROMSelected
        : selectedLang === LyricLang.__ROM__
    const showLyricES = showSideBySide
        ? isLyricESSelected
        : selectedLang === LyricLang.__ES__

    return (
        <section className={styles.section}>
            <h2 className={styles.title}>Letra</h2>
            <div className={styles.song_lyrics_options}>
                {lyric && (
                    <span
                        data-active={showLyricJP}
                        className={styles.song_lang}
                        onClick={() => handleLyricLang(LyricLang.__JP__)}
                    >
                        Japonés
                    </span>
                )}
                {lyric_rom && (
                    <span
                        data-active={showLyricROM}
                        className={styles.song_lang}
                        onClick={() => handleLyricLang(LyricLang.__ROM__)}
                    >
                        Romaji
                    </span>
                )}
                {lyric_es && (
                    <span
                        data-active={showLyricES}
                        className={styles.song_lang}
                        onClick={() => handleLyricLang(LyricLang.__ES__)}
                    >
                        Español
                    </span>
                )}
                {!noLyric && (
                    <span
                        data-active={showSideBySide}
                        className={`${styles.song_lang} ${styles.side_by_side}`}
                        onClick={handleSideBySide}
                    >
                        Side by side
                    </span>
                )}
            </div>
            {noLyric && (
                <div>No hay ninguna letra disponible para esta canción.</div>
            )}
            <div className={styles.song_lyrics}>
                {lyric && showLyricJP && (
                    <div className={styles.song_lyric_jp}>
                        <h3>Japonés</h3>
                        <RichText
                            song
                            content={lyric}
                            alt={`Letra de ${name} en japonés`}
                        />
                    </div>
                )}
                {lyric_rom && showLyricROM && (
                    <div className={styles.song_lyric_rom}>
                        <h3>Romaji</h3>
                        <RichText
                            song
                            content={lyric_rom}
                            alt={`Letra de ${name} en romaji`}
                        />
                    </div>
                )}
                {lyric_es && showLyricES && (
                    <div className={styles.song_lyric_es}>
                        <h3>Español</h3>
                        <RichText
                            song
                            content={lyric_es}
                            alt={`Letra de ${name} en español`}
                        />
                    </div>
                )}
            </div>
            {translators && (
                <div className={styles.song_translators}>
                    Traducción:{' '}
                    {song.translators?.map((translator, index) => (
                        <React.Fragment key={translator.username}>
                            <Link to={`/users/${translator.username}`}>
                                {translator.name}
                            </Link>
                            {index < song.translators!.length - 1 && `, `}
                            {index === song.translators!.length - 1 && `.`}
                        </React.Fragment>
                    ))}
                </div>
            )}
        </section>
    )
}

export default SongLyrics
