// extracted by mini-css-extract-plugin
export var section = "SongLyrics-module--section--2b5M+";
export var title = "SongLyrics-module--title--3fdP8";
export var song_lyrics_options = "SongLyrics-module--song_lyrics_options--bBv3H";
export var song_lang = "SongLyrics-module--song_lang--Emkq4";
export var side_by_side = "SongLyrics-module--side_by_side--OqsFR";
export var song_lyrics = "SongLyrics-module--song_lyrics--7vwS-";
export var song_lyric_jp = "SongLyrics-module--song_lyric_jp--LvuJc";
export var song_lyric_es = "SongLyrics-module--song_lyric_es--2-WKL";
export var song_lyric_rom = "SongLyrics-module--song_lyric_rom--Bj9Hv";
export var song_translators = "SongLyrics-module--song_translators--NvNBI";