import React from 'react'
import videojs from 'video.js'
// Components
import Player from 'components/UIElements/Player'
import SocialIcons from 'components/UIElements/SocialIcons'
// Models
import { ISong } from 'models/Song'
// Styles
import * as styles from './SongMedia.module.css'

type SongMedia = {
    song: ISong
}

const SongMedia: React.FC<SongMedia> = ({ song }) => {
    const { youtube, youtubeMusic, spotify, amazonMusic, deezer } = song
    const { cc_es, cc_rom, cc_jp } = song
    const captions: videojs.TextTrackOptions[] = []
    if (cc_es) {
        captions.push({
            src: cc_es.file.url,
            label: 'Español',
            language: 'Español',
            default: true,
        })
    }
    if (cc_rom) {
        captions.push({
            src: cc_rom.file.url,
            label: 'Romaji',
            language: 'Romaji',
            default: false,
        })
    }
    if (cc_jp) {
        captions.push({
            src: cc_jp.file.url,
            label: 'Japonés',
            language: 'Japonés',
            default: false,
        })
    }

    if (!youtube && !youtubeMusic && !spotify && !amazonMusic && !deezer) {
        return <></>
    }

    return (
        <section className={styles.section}>
            {(youtube || youtubeMusic) && (
                <figure className={styles.media}>
                    {youtube && <Player src={youtube} subtitles={captions} />}
                    {!youtube && youtubeMusic && (
                        <Player src={youtubeMusic} subtitles={captions} />
                    )}
                </figure>
            )}

            <SocialIcons
                className={styles.song_streaming}
                youtube={youtube}
                youtubeMusic={youtubeMusic}
                spotify={spotify}
                deezer={deezer}
                amazonMusic={amazonMusic}
            />
        </section>
    )
}

export default SongMedia
